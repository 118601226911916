import Vue from 'vue'
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
	'en': {
		// views\Home\Welcome.vue
		wlc_1 		: 'Make your work easy',
		wlc_2 		: 'with our',
		wlc_3 		: 'digital marketing platform',
		wlc_4 		: 'Building a successful business is a challenge. I am highly energetic in Digital Marketing, online business and Content Rankings.',
		wlc_5 		: 'Contact us',
		wlc_6 		: 'Get started',
		wlc_7 		: 'WHAT WE OFFER',
		wlc_8 		: 'Complete features at your hand to growth your business',
		wlc_9 		: 'Email marketing',
		wlc_10		: 'We provide a simple way to build your campaigns and send emails at the right moment triggered by customer actions.',
		wlc_11		: 'Leads management',
		wlc_12		: 'SenderLead provide an easier process to acquiring and managing leads until the point where they make a purchase.',
		wlc_13		: 'Landing page',
		wlc_14		: 'Build your landing page and let\'s growing the quality of your content traffic and quantity of your visitors.',
		wlc_15		: 'Content templating',
		wlc_16		: 'You have a different tools at your hand to edit or build an awesome & valuable Landing page or Email content.',
		wlc_17		: 'Content marketing',
		wlc_18		: 'Creating and sharing valuable content to attract prospects attention and generate leads to engage an community of users.',
		wlc_19		: 'Audience targeting',
		wlc_20		: 'Establish audience and look at what they do with your content products or services - not just what they say they do.',
		wlc_21		: 'OUR APPROACH & QUALITIES',
		wlc_22		: 'We have a perfect approach to safety and quality across all our services.',
		wlc_23		: 'Create your teams <br>Organise your work',
		wlc_24		: 'Create your teams and invite your team members to help grow and scale your business. SenderLead is built for every member of your business to plan, organise, and release great strategy and sales.',
		wlc_25		: 'With efficiency to unlock more opportunities',
		wlc_26		: 'Reach your customers worldwide via a different tools. Leads may come from various sources or activities, get everything you need to achieve your sales an marketing goals.',
		wlc_27		: 'We deliver a high quality and results you need',
		wlc_28		: 'With SenderLead you can collect good audience and generate leads with a simple and safe tools that will help you to grow faster',
		wlc_29		: 'Create email campaigns and social media Ad.',
		wlc_30		: 'Built with customization and ease-of-use to collect leads.',
		wlc_31		: 'Quickly create and share content with the public.',
		wlc_32		: 'Are you ready to grow faster ?',
		wlc_33		: 'Create your own experience',
		wlc_34		: 'Get Started For Free',
		
		// views\Home\ContactUs.vue
		cntus_1 : 'Submit your request',
		cntus_2 : 'You can submit a request by selecting the appropriate department below.',
		cntus_3 : 'Contact Email',
		cntus_4 : 'Your Contact Email',
		cntus_5 : 'Department',
		cntus_6 : ['- Choose Department -', 'General question', 'Technical support', 'Payment and billing', 'Change details', 'Activation and renewal'],
		cntus_7 : 'Select department',
		cntus_8 : ['Subject', 'Your Subject'],
		cntus_9 : ['Message', 'Type your Message Here....'],
		cntus_10: 'Request saved successfully',
		cntus_11: 'Submit Your Message ',
		cntus_12: 'You can tell us about your project, or what you\'d like to achieve.',
		cntus_13: 'Get In Touch',
		cntus_14: 'We’re here to help, with 24/7 outstanding support. Ask us about the product, our resources or features. Whatever you need help with.',
		cntus_15: 'If you are looking for more information about SenderLead, need a help, need a quote, or if you want to chat about an upcoming project, contact us any time. We are available for projects worldwide.',
		cntus_16: 'We’d love to hear about any idea you have in mind. Drop us a note here and we’ll get back to you within 2-4 days.',
		cntus_17: 'You can also contact us at :',
		cntus_18: 'General Inquiries',
		cntus_19: 'Voice support',
		
		// views\Home\PrivacyPolicy.vue
		privacyp_1 : 'Privacy Policy',
		privacyp_2 : 'Introduction',
		privacyp_3 : 'This Privacy Policy explains our policy regarding the collection, use and disclosure of your personal information.',
		privacyp_4 : 'All your data in SenderLead is private by default and can only be viewed by you or those you explicitly decide share it with (such as when sending content to your clients or your teams). We operate our business fully on monthly subscriptions, with no hidden costs or clauses. We will never sell or expose your leads, contacts, data, or details to any third parties or advertisers that would put us in conflict with your interests.',
		privacyp_5 : 'Privacy',
		privacyp_6 : 'SenderLead will never sell, share, or make your data accessible to any third parties. You always have full control over your data and can choose to delete it at any time.',
		privacyp_7 : 'Security',
		privacyp_8 : 'Providing a secure site is essential for your peace of mind. We have implemented encryption protocols conforming to the Secure Socket Layers (SSL) protocol to safeguard all of the information you send and receive. All information is stored on our servers in a secure location. Your data is fully encrypted in transit (TLS via HTTPS) and in the database (256-bit Advanced Encryption Standard). We\'re committed to using the highest grade technology to secure and protect your data.',
		privacyp_9 : ['Updated on', 'September 16, 2020'],
		privacyp_10: 'More information contact us at :',
		
		// views/Home/TermsOfService.vue
		termservice_1	: 'Terms of Service',
		termservice_2	: 'Welcome to SenderLead!',
		termservice_3	: 'This is a contract between you and the SenderLead. This contract refers to SenderLead. as “we,” “us,” or “our.” This contract applies to any SenderLead. Office Online and offline (and their successor sites), services (including pre-release services) and software, including all updates, support, and content. This contract refers to all of these as the “service.” This contract also covers your use of any additional SenderLead. services for which you sign up while this contract is in force, unless other terms accompany those services. If so, those terms apply. You represent that you are at least 18 years of age or have attained the age of majority where you live, and that all information you supply is true and correct.',
		termservice_4	: 'Who may use the services',
		termservice_5	: 'You may only use the service if you agree to these terms. If you do not agree, do not use the service. This contract limits our liability and disclaims warranties for the service to the maximum extent permitted by law. Please read these sections of the contract carefully.',
		termservice_6	: 'Creating An Account',
		termservice_7	: 'If you create an account to use the service, you may start using the service as soon as you have finished the sign-up process. As indicated during the setup process, some parts of the service may not be available right away while we configure them for your use.',
		termservice_8	: 'By registering an account or accessing any SenderLead services available via SenderLead websites and/or mobile application, you agree to all the terms and conditions.',
		termservice_9	: 'Privacy',
		termservice_10	: ['Our', 'Privacy Policy', 'describes how we handle the information you provide to us when you use our services.'],
		termservice_11	: 'Account Security',
		termservice_12	: 'You may need to create an account to use some of our Services. You are responsible for safeguarding your account, so use a strong password and limit its use to this account. Please notice, the one who has access to the login email is the one who owns the account. It is your responsibility to keep your email credentials secure. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above.',
		termservice_13	: 'Modification and Termination of Services',
		termservice_14	: 'SenderLead reserves the right to modify, update or discontinue our Services, or any features or portions thereof, without prior notice. You agree that we can suspend or terminate your right to access our Services at any time without notice, obligation or liability to you should you be found in violation of the Terms.',
		termservice_15	: 'You may also terminate this Agreement at any time, for any reason or for no reason, by deleting your account through the Service and/or sending us an email at <b>contact@senderlead.com</b> to delete Your Data.',
		termservice_16	: 'Limitation of Liability',
		termservice_17	: 'You can recover from the SenderLead. parties only direct damages up to an amount equal to your service charge for one month or the equivalent of $5 USD (whichever is greater). To the extent permitted by law, you cannot recover any other damages from the SenderLead. parties, including consequential, lost profits, special, indirect, or incidental damages. This limitation applies to anything related to the service, content (including code) on third party Internet sites, third party programs or third party conduct, viruses or other disabling features that affect your access to or use of the service, incompatibility between the service and other services, software, and hardware, delays or failures you may have in initiating, conducting or completing any transmissions or transactions in connection with the service in an accurate or timely manner, and claims for breach of contract, breach of warranty, guarantee or condition, strict liability, negligence, or other tort. It also applies even if this remedy does not fully compensate you for any losses, or fails of its essential purpose, or SenderLead. knew or should have known about the possibility of the damages. Some states do not allow the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you. They also may not apply to you because your province or country or region may not allow the exclusion or limitation of incidental, consequential, or other damages.',
		termservice_18	: '<b>Updated on:</b> September 18, 2020',
		
		// views/Home/Pricing.vue ---- Not completed
		pricing_1	: 'Pay As You Grow',
		pricing_2	: 'You can start your FREE 7 days trial. No credit card needed.',
		pricing_3	: 'Start with your free account today.',
		
		// views/Auth/Forgot.vue
		forgot_1	: 'You can reset your password here',
		forgot_2	: ['Success!', 'A reset link has been sent to your email address.'],
		forgot_3	: 'Type your email Address',
		forgot_4	: 'Error, unable to check at this time. If the problem persists, please contact support.',
		forgot_5	: 'Send reset link ',
		forgot_6	: 'Not registered? ',
		forgot_7	: 'Create an account',
		
		// views/Auth/Login.vue
		login_1		: 'SIGNIN',
		login_2		: 'Please signin using your email and password',
		login_3		: 'Your Email',
		login_4		: 'Password',
		login_5		: 'Forget your password?',
		login_6		: 'These credentials do not match our records.',
		login_7		: 'Create an account',
		login_8		: 'Sign in',
		
		// views/Auth/Register.vue
		register_1	: 'SIGNUP',
		register_2	: 'Create your SenderLead Account',
		register_3	: 'Full name',
		register_4	: 'Your Email',
		register_5	: 'Password',
		register_6	: 'Retype your password',
		register_7	: 'Error, unable to register at this time. If the problem persists, please contact support.',
		register_8	: ['By proceeding, you agree to the SenderLead', 'Platform Policies'],
		register_9	: 'You have an account ?',
		register_10	: 'Signin',
		register_11	: 'Get started ',
		
		// views/Auth/Reset.vue
		reset_1	: 'You can reset your password now',
		reset_2	: 'New Password',
		reset_3	: 'Retype your new password',
		reset_4	: 'Error, unable to reset at this time. If the problem persists, please contact support.',
		reset_5	: 'Reset my password ',
		reset_6	: 'SenderLead. All rights reserved',
		
		// views/Auth/TeamInvitation.vue
		teaminv_1	: ['You are invited from', 'to join'],
		teaminv_2	: 'Get started ',
		teaminv_3	: 'SenderLead. All rights reserved',
		
		// views/User/HelpCenter/MyTickets.vue
		mytickets_1	: 'New Ticket',
		mytickets_2	: 'Submit a ticket',
		mytickets_3	: 'You can submit a ticket by selecting the appropriate department below.',
		mytickets_4	: ['Contact Email', 'Your Contact Email', 'Your Contact Email'],
		mytickets_5 : 'Department',
		mytickets_6 : ['- Choose Department -', 'General question', 'Technical support', 'Payment and billing', 'Change details', 'Activation and renewal', 'Select department'],
		mytickets_7 : ['Subject', 'Your Subject'],
		mytickets_8 : ['Message', 'Type your Message Here....'],
		mytickets_9 : 'Submit Your Message',
		mytickets_10: 'You can tell us about your project, or what you\'d like to achieve.',
		mytickets_11: 'Get In Touch',
		mytickets_12: 'We’re here to help, with 24/7 outstanding support. Ask us about the product, our resources or features. Whatever you need help with.',
		mytickets_13: 'If you are looking for more information about SenderLead, need a help, need a quote, or if you want to chat about an upcoming project, contact us any time. We are available for projects worldwide.',
		mytickets_14: 'We’d love to hear about any idea you have in mind. Drop us a note here and we’ll get back to you within 48 hours.',
		
		// views/User/HelpCenter/NewTicket.vue
		newticket_1	: 'Support requests',
		newticket_2	: 'New support request', // Open new support request
		newticket_3	: ['Subject:', 'You', 'Support', 'Respond:', 'Respond'],
		newticket_4	: ['Id', 'Subject', 'Department', 'Status', 'Responses', 'Actions'],
		newticket_5	: ['View', 'Responses'],
		newticket_6	: ['General Question', 'Technical support', 'Payment and billing', 'Change details', 'Activation and renewal'],
		
		// views/User/Payment/Components/FundsReports.vue
		fundsreports_1	: ['Funds ID', 'Description', 'Amount', 'Status', 'Date Time'],
		
		// views/User/Payment/Components/InvoicesReceipts.vue
		invrcpt_1		: ['Invoice ID', 'Title', 'Amount', 'Description', 'Status', 'Date Time'],
		
		// views/User/Payment/Components/Products.vue
		products_1		: ['Name', 'Type', 'Price', 'Status', 'Auto renew', 'Next Billing', 'Created at'],
		
		// views/User/Payment/Billing.vue
		billing_1		: 'Invoices & Funds',
		billing_2		: 'Add funds',
		billing_3		: ["Funds Reports", "Invoices & Receipts", "My Products"],
		
		// views/User/Leads/Settings/LeadDetails/MyActivity.vue
		myactivity_1	: 'Old status:',
		myactivity_2	: 'New status',
		myactivity_3	: ['Field', 'Old value', 'New value'],
		myactivity_4	: ['Field', 'value'],
		myactivity_5	: ["Lead Added", "Status Changed", "General Details", "Custom Fields", "New Custom Fields"],
		
		// views/User/Leads/Settings/LeadDetails/GeneralDetails.vue
		generaldtls_1	: ['List name', 'Lead source'],
		generaldtls_2	: ['Full name', 'Full Name', 'Changes Saved'],
		generaldtls_3	: ['First name', 'First name', 'Changes Saved'],
		generaldtls_4	: ['Last name', 'Last name', 'Changes Saved'],
		generaldtls_5	: ['Email Address', 'Email Address', 'Changes Saved'],
		generaldtls_6	: ['Phone Number', 'Phone Number', 'Changes Saved'],
		
		// views/User/Leads/Settings/LeadDetails/CustomFields.vue ---- Not completed
		
		
		// views/User/Leads/Settings/EmailContent.vue
		emlcontent_1	: 'Sender',
		emlcontent_2	: 'Sender name',
		emlcontent_3	: ['From', 'Email from'],
		emlcontent_4	: ['Subject', 'Email Subject'],
		emlcontent_5	: ['Text plain / HTML Code', 'Text Editor', 'Choose Template'],
		emlcontent_6	: ['Choose your default editor:', 'Text plain / HTML Code', 'Text Editor'],
		emlcontent_7	: 'Available tags',
		emlcontent_8	: '*Field Required add your message',
		emlcontent_9	: ['Preview', 'Use template', 'Customize template'],
		emlcontent_10	: ['*Click to the button save', 'in Top bar before using template.', 'Use Template', 'Discard'],
		
		// views/User/Leads/Settings/LeadDetails.vue
		leaddetails_1	: ["General Details", "Custom Fields", "My Activity"],
		
		// views/User/Leads/MyLeads.vue ---- Not completed
		myleads_1		: 'My Leads',
		myleads_2		: 'Add new leads',
		myleads_3		: 'Search by (email, phone, full name, first name, last name, custom fields)',
		myleads_4		: ['Lists', 'My Lists', 'Status', 'Statuses'],
		myleads_5		: 'All columns',
		myleads_6		: 'Selected columns only',
		myleads_7		: ['Export', 'Export as csv', 'Export as excel'],
		myleads_8		: ['items', 'item', 'selected', 'Choose your leads', 'Change list', 'Change statuses', 'Leads statuses'],
		myleads_9		: 'Duplicate',
		myleads_10		: 'Lead Details',
		myleads_11		: ['Are you sure ?', 'Do you really want to delete this lead ?', 'Cancel', 'Delete'],
		myleads_12		: ['Close', 'Save changes', 'Send email'],
		myleads_13		: ['Choose list', 'List name', 'Choose', 'New', 'list'],
		myleads_14		: 'Custom field',
		myleads_15		: ['Cancel', 'Confirm'],
		myleads_16		: ['Full name',	'First name', 'Last name', 'Email address', 'Phone'],
		// myleads_17		: ['Full name', 'First name', 'Last name', 'Email', 'Phone', 'Source', 'Status', 'List name', 'Generated date'],
		myleads_18		: ["", "Lead Details", 'Full name', 'First name', 'Last name', 'Email', 'Phone', 'Source', 'Status', 'List name', 'Generated', 'Options'],
		myleads_19		: ['Settings', 'Delete'],
		myleads_20		: ['Show Hide Columns'],
		
		// views/User/Account/MyProfile.vue ---- Not completed
		myprofile_1		: 'Account',
		myprofile_2		: 'General Account Settings',
		myprofile_3		: ['Full Name', 'Your fullname should include your authentic name.'],
		myprofile_4		: ['Email Address', 'Your Email Address', 'Your personal or professional email address'],
		myprofile_5		: 'Nickname',
		myprofile_6		: ['Phone number', 'Your phone number'],
		myprofile_7		: ['Country', 'Your Country'],
		myprofile_8		: ['Timezone', 'Your Timezone'],
		myprofile_9		: ['Region', 'Your Region'],
		myprofile_10	: ['Main Address', 'Your Address'],
		myprofile_11	: 'You can view or edit your account information at any time. We\'ve categorized this information by type so you can easily find what you\'re looking for.',
		myprofile_12	: 'Update login details',
		myprofile_13	: ['Current Password', 'It\'s recommended to use a strong password that you\'re not using elsewhere', 'New Password', 'Re-type new password', 'Save Changes'],
		myprofile_14	: 'We recommend that your password does not include any personal information (avoid, for example, name, surname, date of birth, phone number, etc.)',
		myprofile_15	: 'Update work details',
		myprofile_16	: ['Company', 'Independent/Self-employed Person'],
		myprofile_17	: ['Company Name', 'Your company name', 'We\'ll never share your company name with anyone else.'],
		myprofile_18	: ['Position', 'Your Position', 'What is your job title ?'],
		myprofile_19	: ['Tax number', 'Corporate VAT or Sales Tax Number'],
		myprofile_20	: 'Website',
		myprofile_21	: ['About', 'Description'],
		myprofile_22	: ['Save Changes', 'You’re the boss of your account.'],
		myprofile_23	: ['Your preferred language', 'Language', 'Choose language', 'Select Your Preferred Language'],
		myprofile_24	: ['English'],
		myprofile_25	: ["Account Settings", "Change password", "Work details", "Language"],
		
		
		// views/User/Account/MySettings.vue -- Not completed
		mysettings_1	: 'Default team',
		mysettings_2	: ['Owner', 'Admin', 'Editor', 'Viewer'],
		mysettings_3	: 'My Teams',
		mysettings_4	: ['View invitations', 'Create New Team'],
		mysettings_5	: 'Currently',
		mysettings_6	: 'Creation date',
		mysettings_7	: 'Remove',
		mysettings_8	: ['Team name', 'Create Team'],
		mysettings_9	: ['Member Email', 'Email Address', 'Member Role', 'Choose role', 'Send Invitation'],
		mysettings_10	: ['Are you sure ?', 'Cancel', 'leave', 'delete'],
		mysettings_11	: 'Do you really want to <b>{action}</b> this team ? This process cannot be undone.',
		mysettings_12	: 'Do you really want to <b>remove</b> this member ? This process cannot be undone.',
		mysettings_13	: ['Leads Statuses', 'Save changes', 'Add status'],
		mysettings_14	: ['Status', 'Is selected', 'Default status', 'Qualify status', 'Status title'],
		mysettings_15	: ["Teams & Members", "Leads Statuses"],
		mysettings_16	: ["Id", 'Full Name', 'Email', 'Role', 'Status', 'Actions'],
		mysettings_17	: ["Id", 'Name', 'Members', 'Owner', 'Created at', 'Actions'],
		mysettings_18	: ["Id", 'Team', 'Invited Email', 'Role', 'Invited by', 'Status', 'Created at', 'Actions'],
		mysettings_19	: ['Manage Team', 'Invite Member', 'Leave Team', 'Remove Team'],
		mysettings_20	: ['Accept Invitation', 'Remove Invitation', 'Ignore Invitation', 'Resend Invitation'],
		
		
		// views/User/Dashboard/Dashboard.vue
		dashboard_1		: ['Total Leads', 'Total Leads Qualified', 'Total Leads Pending', 'Total Emails Fresh'],
		dashboard_2		: ["Fresh", "Received", "Opens", "Clicks", "Unsub", "Failed"], // Soft/Hord Bounce
		
		
		// views/User/Dashboard/Components/CampaignsStats.vue
		cmpgnstats_1	: ['Campaigns performance', 'All Email Campaigns'],
		cmpgnstats_2	: ['Conversions per delivered', 'Total Received', 'Total Opened', 'Total Clicked', 'Total Unsubscribed'],
		
		// views/User/Dashboard/Components/MapStats.vue
		mapstats_1		: 'Show less',
		mapstats_2		: 'Show more ({total})',
		mapstats_3		: 'Visitors From {country}',
		
		
		// views/User/Resources/Domains.vue
		domains_1		: 'My Domains',
		domains_2		: ['Show', 'Per Page'],
		domains_3		: 'DNS Records',
		
		// views/User/Resources/FbPages.vue
		fbpages_1		: 'Facebook Pages',
		fbpages_2		: ['Show', 'Per Page'],
		fbpages_3		: ['Page name', 'Main category', 'Added by', 'Token', 'Token status', 'Ads access', 'Created at', 'Actions'],
		
		// views/User/Resources/Senders.vue
		senders_1		: 'Sender & WebPages',
		senders_2		: ['Show', 'Per Page'],
		senders_3		: 'Change Domain Name',
		senders_4		: 'Traffic Monitoring',
		senders_5		: ['Web Page', 'Preview'],
		senders_6		: ['Page Editor', '*Click to the button save <i class="fa fa-save text-dark"></i> in Top bar before using page.', 'Use Page', 'Discard'],
		senders_7		: ['Are you sure ?', 'Do you really want to use this page ? This process cannot be undone.', 'Cancel', 'Confirm'],
		senders_8		: ['Sender Config', 'Activate tls certification', 'Time between email (Second)', 'Time in second', 'Number of emails per send', 'Number of emails by campaign', 'Save Changes'],
		senders_9		: ['Title', 'Status', 'Type', 'Smtp', 'Quota', 'Domain', 'Created at', 'Actions'],
		senders_10		: ['Domain name', 'Traffic monitoring', 'Web page', 'Send config'],
		
		
		// views/User/Campaigns/Components/Email/Domains/AddNewDomain.vue -- Not completed
		addnewdomain_1	: 'Authorize your domain to create new senders linked to this domain without having to verify each email address. E.g. By authorizing \'mydomain.com\' you can add new @mydomain.com senders without having to verify each email address.',
		addnewdomain_2	: 'Incomplete Step 1 Please Choose Your Sender',
		addnewdomain_3	: ['Domain name', 'Search for domain ...', 'Search', 'We accept all domain name levels like : ( domain.com, domain.us, domain.co.us, ...... )', 'If this domain name is yours click to <b>Connect Your DNS</b> button to configure your dns'],
		addnewdomain_4	: ['* You don\'t have funds to your account', 'Add funds'],
		addnewdomain_5	: ['Sender email', 'Sender', 'Connect Domain', 'Connect Your DNS'],
		
		// views/User/Campaigns/Components/Email/Domains/DnsRecords.vue
		dnsrecords_1	: 'Set <b>Domain Records</b> for <span class="text-success">{DomainName}</span>',
		dnsrecords_2	: 'Export',
		dnsrecords_3	: ['Record', 'Type:', 'Host:', 'TTL:', 'Verify Record'],
		dnsrecords_4	: ['MAIL SETTINGS', '<i class="fa fa-exclamation-triangle"></i> Please don\'t forget to change your mail setting as <b>Private Email</b>', 'It may take up to <u>6-12 hours</u> for DNS changes to fully propagate.'],
		
		// views/User/Campaigns/Components/Email/Domains/ExistingDomains.vue
		existdomains_1	: 'We\'ll never share your email with anyone else.',
		existdomains_2	: ['Sender email', 'Sender'],
		
		// views/User/Campaigns/Components/Email/SMTP/FreeSMTP.vue
		freesmtp_1		: ['SMTP', 'Choose smtp', '*Select sender', 'Sender email', 'Sender'],
		
		// views/User/Campaigns/Components/Email/SMTP/MySMTP.vue
		mysmtp_1		: 'Sender not allowed',
		mysmtp_2		: ['Size', 'Emails', 'Status', 'Sender name', 'Check your config', 'Sender email', 'No items to display.'],
		
		// views/User/Campaigns/Components/Email/SMTP/NewSMTP.vue -- Not completed
		newsmtp_1		: ['*Not allowed, Basic plan required !', 'Size', 'Emails', 'Price', 'No items to display.'],
		newsmtp_2		: ['Out of Stock !', 'We are currently out of stock on this item so orders for it have been suspended until more stock in available. For further information, please contact us.', 'Close'],
		
		// views/User/Campaigns/Components/Email/SMTP/TrafficMonitoring.vue
		traffcmtr_1		: ['Last time refresh', 'Loading...'],
		traffcmtr_2		: ['Inbound Traffic', 'rcpts', 'msgs', 'kbytes'],
		traffcmtr_3		: ['Outbound Traffic', 'rcpts', 'msgs', 'kbytes'],
		traffcmtr_4		: ['Queues', 'rcpts', 'domains', 'kbytes', 'SMTP', 'discard', 'file', 'pipe', 'bounce Processor'],
		traffcmtr_5		: ['Connections', 'in', 'out'],
		traffcmtr_6		: ['Domain Names', 'cached', 'pending'],
		traffcmtr_7		: ['Spool', 'files', 'directories', 'initialization', 'max Rcp'],
		traffcmtr_8		: ['Status', 'Started on', 'Uptime'],
		traffcmtr_9		: [ 'total', 'last hour', 'top/hour', 'last minute', 'top/minute' ],
		
		// views/User/Campaigns/Components/Email/EmailContent.vue
		emailcontent_1	: 'Email Content:',
		emailcontent_2	: '* Step not completed',
		emailcontent_3	: ['From', 'Email From'],
		emailcontent_4	: ['Subject', 'Email Subject'],
		emailcontent_5	: ['Text plain / HTML Code', 'Text Editor', 'Choose Template'],
		emailcontent_6	: ['Choose your default editor:', 'Text plain / HTML Code', 'Text Editor', 'Available tags'],
		emailcontent_7	: '*Field Required add your message',
		emailcontent_8	: ['Choose Template', 'Preview'],
		emailcontent_9	: ['Edit Template', '*Click to the button save <i class="fa fa-save text-dark"></i> in Top bar before using template.', 'Use Template', 'Discard'],
		
		// views/User/Campaigns/Components/Email/GeneralDetails.vue -- Not completed
		gendetails_1	: 'Overview:',
		gendetails_2	: ['Campaign name', 'Changes Saved'],
		gendetails_3	: ['Campaign vertical', 'Choose Vertical', 'Custom vertical', 'Changes Saved'],
		gendetails_4	: ['Email test', 'Changes Saved'],
		gendetails_5	: ['Sender email', 'sender', 'Changes Saved'],
		gendetails_6	: 'Total Recipients',
		gendetails_7	: ['Newsletter', 'Promotional - US', 'Automotive', 'Health/Beauty', 'Diet/Weightloss', 'Surveys', 'One Time Purchase', 'Mortgage/Refinance', 'Miscellaneous', 'Credit Report', 'Biz Opp/MLM', 'Seasonal', 'Auto Warranty', 'Travel', 'Cash Advance/PayDay Loan', 'Debt/Personal Finance', 'Health Insurance', 'Education', 'Home/Home Improvement', 'Psychological', 'Advertorial', 'TV/Entertainment', 'Auto Financing', 'UK', 'Email submit', 'International', 'Other'],
		
		// views/User/Campaigns/Components/Email/Global.vue
		emailglobal_1	: ['Campaign Details','Email Details','SMTP & Confirmation'],
		emailglobal_2	: 'Campaign name',
		emailglobal_3	: ['Your vertical (Optional)', 'Select Your Vertical', 'Custom vertical'],
		emailglobal_4	: ['Choose Recipients', 'Select Your List', 'Lists', '*Required please select your lists', 'Shuffle Emails'],
		emailglobal_5	: ['Recipients Status', 'Choose Recipients', 'Recipients', 'Select recipients'],
		emailglobal_6	: 'Your list contain <b>({count})</b> email not valid', // <template v-if="count > 1">s</template>
		emailglobal_7	: ['Total of Recipients', 'total recipients'],
		emailglobal_8	: 'Your email test',
		emailglobal_9	: 'Next',
		emailglobal_10	: ['From', 'Email From'],
		emailglobal_11	: ['Subject', 'Email Subject'],
		emailglobal_12	: ['Text plain / HTML Code', 'Text Editor', 'Choose Template'],
		emailglobal_13	: ['Choose your default editor:', 'Text plain / HTML Code', 'Text Editor', 'Available tags', '*Field Required add your message', 'previous', 'Next'],
		emailglobal_14	: ['Create OR Connect Your Own SMTP', 'Authenticated domain improves deliverability and reputation. Ideal for production sending.', 'Authenticate your Domain', 'OR', 'USE OUR FREE SMTP', 'A quick method for getting started sending. Recommended for testing only.', 'Verify a Single Sender'],
		emailglobal_15	: ['*Choose your sender', 'Sender configured successfully click finish or Schedule', 'previous', 'Schedule', 'Save As Draft', 'Finish'],
		emailglobal_16	: ['Suppression file', 'Validate'],
		emailglobal_17	: ['Schedule Send', 'Date Time', 'Choose Your Date Time', '* Choose a date', 'Your can change it from table campaign', 'Schedule'],
		emailglobal_18	: ['Sender Email', 'Validate'],
		emailglobal_19	: ['Advanced SMTP', 'Choose Your Sender'],
		emailglobal_20	: ['Domain Name Confirmation', 'Finish'],
		emailglobal_21	: ['Choose Template', 'Preview', 'Use template', 'Customize template'],
		emailglobal_22	: ['Edit Template', '*Click to the button save <i class="fa fa-save text-dark"></i> in Top bar before using template.', 'Use Template', 'Discard'],
		emailglobal_23	: ['Add New Domain Name', 'Choose an Existing Domain Name'],
		emailglobal_24	: ['My Sender', 'New Sender'],
		emailglobal_25	: ['Newsletter', 'Promotional - US', 'Automotive', 'Health/Beauty', 'Diet/Weightloss', 'Surveys', 'One Time Purchase', 'Mortgage/Refinance', 'Miscellaneous', 'Credit Report', 'Biz Opp/MLM', 'Seasonal', 'Auto Warranty', 'Travel', 'Cash Advance/PayDay Loan', 'Debt/Personal Finance', 'Health Insurance', 'Education', 'Home/Home Improvement', 'Psychological', 'Advertorial', 'TV/Entertainment', 'Auto Financing', 'UK', 'Email submit', 'International', 'Other'],
		emailglobal_26	: ['Fresh Emails', 'Received Emails', 'Opened Emails', 'Clicked Emails', 'Unsubscribes', 'Failed Emails'], // Soft Bounce
		
		// views/User/Campaigns/Components/Email/SenderSmtp.vue
		sendersmtp_1	: '* Step not completed choose your sender',
		sendersmtp_2	: ['Authenticate your Domain', 'Verify a Single Sender'],
		sendersmtp_3	: ['Title:', 'Sender Status:', 'Sender type:', 'SMTP:'],
		sendersmtp_4	: ['Sender Email', 'Validate'],
		sendersmtp_5	: ['Advanced SMTP', 'Choose Your Sender'],
		sendersmtp_6	: ['Domain Name Confirmation', 'Validate'],
		sendersmtp_7	: ['Add New Domain Name', 'Use an Existing Domain Name'],
		sendersmtp_8	: ['My Sender', 'New Sender'],
		
		// views/User/Campaigns/Components/Email/SuppressionFile.vue
		suppfile_1		: ['Choose your file :', 'Choose file', '*Required please select your file', 'Add new suppression file', 'File name :', 'Choose an existing file'],
		suppfile_2		: ['File Content (.txt)', 'We accept text file only', 'File Content Type', 'Text Emails', 'MD5 Emails'],
		
		// views\User\Campaigns\Components\Facebook\Global.vue
		fbglobal_1		: ['WELCOME TO FACEBOOK Ads!', 'You don\'t have to be an expert to start advertising on Facebook. Create and run campaigns using simple self-service tools, and track their performance with easy-to-read reports.', 'Next'],
		fbglobal_2		: ['Choose your page', 'Select page', '* Choose your page'],
		fbglobal_3		: ['Campaign name', '* Campaign name required', 'Daily budget', 'Daily budget', '* Daily budget must be greater than 5 $'],
		fbglobal_4		: ['Objective', 'Choose objective', '* Please choose your objective'],
		fbglobal_5		: ['Ad categories (Optional)', 'Categories'],
		fbglobal_6		: ['Geo locations (Optional)', 'Geo locations'],
		fbglobal_7		: ['previous', 'Next'],
		fbglobal_8		: 'Facebook ads access',
		fbglobal_9		: 'To claim this permission you must be an admin or manager for <b>{PageName}</b>, we will send you request in your facebook page, and you will be receive a facebook notification to accept managing ads from our platform.',
		fbglobal_10		: ['Go facebook', 'Request access', 'Check access'],
		fbglobal_11		: ['Message', 'Insert your text here', '* Message required', 'Creative (Main image)', '* Creative required'],
		fbglobal_12		: ['Call to action'],
		fbglobal_13		: 'Action link',
		fbglobal_14		: ['Start date', 'End date', 'previous', 'Finish'],
		fbglobal_15		: ['Full name', 'First name', 'Last name', 'Email Address', 'Phone Number', 'Generated at', "Custom Field"],
		fbglobal_16		: ['HOUSING', 'CREDIT', 'EMPLOYMENT'],
		
		// views\User\Campaigns\Components\LandingPage\Global.vue
		webforms_1		: 'This feature is not available yet, please come back later.',
		
		// views\User\Campaigns\CreateCampaign.vue
		createcampaign_1: 'Beta',
		createcampaign_2: ['Back', 'Back | Create Campaigns'],
		createcampaign_3: ['Email Campaign', 'Facebook Ads', 'Create'],
		
		// views\User\Campaigns\FbCampaigns.vue
		FbCampaigns_1	: ['Campaigns', 'New campaign'],
		FbCampaigns_2	: ['show/hide Columns', 'Search'],
		FbCampaigns_3	: 'Published on',
		FbCampaigns_4	: ['Fresh Emails', 'Received Emails', 'Opened Emails', 'Clicked Emails', 'Unsubscribes', 'Failed Emails'],
		FbCampaigns_5	: ["General Details", "Email Content", "Sender & SMTP", "DNS & Records"],
		FbCampaigns_6	: ["All", "New", "Drafts", "Scheduled", "Running", "Suspended", "Finished"],
		FbCampaigns_7	: ["Id", 'Campaign name', 'Sender', 'Objective', 'Actions'],
		FbCampaigns_8	: ['Run Sending', 'Edit / Complete'],
		
		// views\User\Campaigns\MyCampaigns.vue
		mycampaigns_1	: ['My Campaigns', 'Create new campaign'],
		mycampaigns_2	: ['show/hide Columns', 'Search'],
		mycampaigns_3	: ['Are you sure ?', 'Do you really want to delete this campaign ? This process cannot be undone.', 'Cancel', 'Delete'],
		mycampaigns_4	: ['Are you sure ?', 'Do you really want to replicate this campaign ?', 'Cancel', 'Replicate'],
		mycampaigns_5	: ['Are you sure ?', 'Do you want really to initialize this campaign ? This action cannot be undone.', 'Cancel', 'Initialize'],
		mycampaigns_6	: 'Details Campaign - {name}', // `Details Campaign - ${DataCampaign.name}`
		mycampaigns_7	: ['Suppression:', 'Validate'],
		mycampaigns_8	: ['Schedule Send', 'Date Time', 'Choose Your Date Time', '* Choose a date', 'Schedule'],
		mycampaigns_9	: ['Campaign Recipients', 'Choose Your List(s)', 'Select List', 'Lists', '*Please select your list(s)', 'Shuffle Emails'],
		mycampaigns_10	: ['Recipients', 'Choose Recipients', 'Recipients', '*Select your recipients'],
		mycampaigns_11	: 'Your list contain <b>({count})</b> <span v-if="count > 1">emails</span><span v-else>email</span> not valid',
		mycampaigns_12	: ['Total of Recipients', 'total recipients', '*Total must be greater than 0', 'Save Changes'],
		mycampaigns_13	: 'Recipients List',
		mycampaigns_14	: 'Total <b>{total}</b> rows',
		mycampaigns_15	: ['Columns', 'show/hide Columns', 'Email actions', 'Email actions'],
		mycampaigns_16	: ['Fresh Emails', 'Received Emails', 'Opened Emails', 'Clicked Emails', 'Unsubscribes', 'Failed Emails'],
		mycampaigns_17	: ['Fresh Emails', 'Sent Emails', 'Received Emails', 'Opened Emails', 'Clicked Emails', 'Unsubscribes', 'Failed Emails'],
		mycampaigns_18	: ['Full name', 'First name', 'Last name', 'Email', 'Phone', 'Action', 'Device', 'DateTime Open', 'Country', 'Region', 'City', 'Timezone', 'Continent', 'Currency'],
		mycampaigns_19	: ["General Details", "Email Content", "Sender & SMTP", "DNS & Records"],
		mycampaigns_20	: ["All", "New", "Drafts", "Scheduled", "Running", "Suspended", "Finished"],
		mycampaigns_21	: ["Id", 'Campaign Name', 'Sender', 'Total emails', 'Total Sent', 'Total Rest', 'Estimate time finish', 'Received', 'Failed', 'Openers', 'Clickers', 'Unsub', 'Started at', 'Finished at', 'Actions'],
		mycampaigns_22	: ['Run Sending', 'Edit / Complete', 'Edit / Preview', 'Change Recipients', 'Schedule Campaign', 'Stop', 'Pause', 'Resume', 'Send Test Email', 'Recipients List', 'Delete', 'Replicate'],
		
		// views\User\Lists\Sources\CsvXLSX.vue
		csvxlsx_1		: ['Import File', 'Mapping & Setting', 'Finish'],
		csvxlsx_2		: ['Upload Your File', 'Upload File', 'Undo', 'Next step'],
		csvxlsx_3		: ['List Name', 'Your Can customize it as you like', 'Your File Fields', 'Mapping With System'],
		csvxlsx_4		: 'Map <b> {label} </b> with',
		csvxlsx_5		: ['-- Ignore Field --', 'Custom Field'],
		csvxlsx_6		: ['previous', 'Finish', 'Success!'],
		csvxlsx_7		: 'Your list contain {count} {count > 1 ? "leads" : "lead"}',
		csvxlsx_8		: 'Go to my leads',
		csvxlsx_9		: ['Full name', 'First name', 'Last name', 'Email Address', 'Phone Number', 'Lead Status', 'Generated at', "Custom Field"],
		
		// views\User\Lists\Sources\FacebookLeadAds.vue
		fbleadads_1		: ['Connect Your Facebook', 'Select Your Page & Form', 'Settings & Finish'],
		fbleadads_2		: ['RETRIEVE YOUR FACEBOOK LEADS', "This tool helps you check for new leads from your Facebook Lead Ads. You'll be redirected to Facebook to log in.", 'Next'],
		fbleadads_3		: ['Choose your page:', 'Select page', "* The page doesn't have any leads form."],
		fbleadads_4		: ['Choose your form:', '<b>*</b>This form is already connected with our system.', 'previous', 'Next'],
		fbleadads_5		: ['Mapping Fields', 'Your List name:', 'List Name', 'Your File Fields', 'Mapping With System'],
		fbleadads_6		: 'Map <b> {label} </b> with',
		fbleadads_7		: ['-- Select Field --', 'Custom Field'],
		fbleadads_8		: 'Import Existing Leads ({count})',
		fbleadads_9		: ['Email Notification', 'Activate Email Notification', 'Add your recipients'],
		fbleadads_10	: ['previous', 'Finish'],
		fbleadads_11	: ['Full name', 'First name', 'Last name', 'Email Address', 'Phone Number', 'Generated at', "Custom Field"],
		
		// views\User\Lists\Sources\TxtFile.vue
		txtfile_1		: ['Import File', 'Setting & Configs', 'Finish'],
		txtfile_2		: ['Upload Your File', 'Upload File', 'Undo', 'Next step'],
		txtfile_3		: ['List name', 'List Name'],
		txtfile_4		: ['File items', '-- Select Field --'],
		txtfile_5		: "Remove duplicate {count > 1 ? field + 's' : field}",
		txtfile_6		: ['Ignore first line', 'previous', 'Finish'],
		txtfile_7		: ['Success!', 'Your list saved successfully.', 'Go to my leads'],
		txtfile_8		: ['List Emails', 'List Phones'],
		
		// views\User\Lists\Sources\WebForms.vue
		srcwebforms_1		: 'This feature is not available yet, please come back later.',
		
		// views\User\Lists\Sources\Webhook.vue
		webhook_1		: ['Create webhook', 'Test & settings', 'Confirm & Finish'],
		webhook_2		: ['SETUP YOUR WEBHOOK', 'Webhook enables you to send lead form data to your list in real-time.'],
		webhook_3		: ['Your list name:', 'List name', 'Continue', '* List name required'],
		webhook_4		: ['Webhook URL:', 'Webhook URL', 'Copy'],
		webhook_5		: ['Instruction', 'Copy Your Webhook URL.', 'Add your webhook URL to your <b>Web Form</b> or your <b>Application</b> that you would connect with your list or send leads from.', 'Send a lead test to your webhook URL.', 'Click <b><i class="fa fa-search"></i> check</b> button to verify propagation.', 'Check'],
		webhook_6		: ['How this work ?', 'To set it up, you will need to add your webhook URL to your lead form or app. The URL is the delivery path.'],
		webhook_7		: 'After the user submits a lead form, an HTTP post request is sent to the configured URL, allowing the lead data to go directly into the list <b>{name}</b>.',
		webhook_8		: ['previous', 'Next'],
		webhook_9		: ['Mapping Fields', 'Your File Fields', 'Mapping With System'],
		webhook_10		: 'Map <b>{label}</b> with',
		webhook_11		: ['-- Select Field --', 'Custom Field'],
		webhook_12		: ['Email Notification', 'Activate Email Notification', 'Add your recipients'],
		webhook_13		: ['previous', 'Finish'],
		webhook_14		: ['Full name', 'First name', 'Last name', 'Email Address', 'Phone Number', 'Generated at', "Custom Field"],
		
		// views\User\Lists\MyLists.vue
		mylists_1		: ['My Lists', 'Add new list'],
		mylists_2		: ['Show:', 'Per Page', 'My Lists', 'Lists', 'Status'],
		mylists_3		: 'Creation Date  :',
		mylists_4		: "Total {total} { total > 1 ? 'Lists' : 'List' }",
		mylists_5		: ['Edit List', 'Creation date:', 'List name', 'List Name', 'List status', '-- Choose status --', 'Active', 'Inactive', 'Save changes'],
		mylists_6		: ['Are you sure ?', 'Do you really want to delete this record ? This process cannot be undone.', 'Cancel', 'Delete'],
		mylists_7		: ["Id", 'Name', 'Status', 'Source', 'Total Leads', 'Qualified', 'Pending', 'Options'],
		mylists_8		: ['Edit', 'View Content', 'Settings', 'Delete List'],
		
		// views\User\Lists\NewSource.vue
		newsource_1		: 'Sources',
		newsource_2		: 'Back',
		
		// views\User\Lists\Settings.vue
		lstsettings_1	: 'List Setting',
		lstsettings_2	: 'Back',
		lstsettings_3	: ['Save changes', 'List name', 'List Name'],
		lstsettings_4	: ['List status', '-- Choose status --', 'Active', 'Inactive'],
		lstsettings_5	: ['Webhook URL:', 'Webhook URL', 'Copy'],
		lstsettings_6	: ['Mapping Fields', 'Email Notification', 'Activate Email Notification', 'Add your recipients'],
		
		// views\User\Lists\Sources.vue
		lstsources_1	: 'Sources',
		lstsources_2	: ['Connect', 'Connect', 'Import File', 'Import File', 'Build'],
		
	},
	'es': {
		
	}
};

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'es', // set fallback locale
    messages, // set locale messages
});

export default i18n;
